(function () {
	'use strict';

	angular
		.module('activation', [])
		.factory('activationMessages', function ($http, $sce) {
			return {
				getActivationMessages: getActivationMessages,
				getUpdateMessages: getUpdateMessages,
			};
			function getActivationMessages(url) {
				return $http
					.get(url + '?cachebust=' + new Date().getTime())
					.then(function (result) {
						for (var parentProperty in result.data) {
							for (var property in result.data[parentProperty]) {
								result.data[parentProperty][property] =
									$sce.trustAsHtml(
										result.data[parentProperty][property]
									);
							}
						}
						return result.data;
					})
					.catch(function (error) {
						console.log('error', error);
					});
			}

			function getUpdateMessages(url) {
				return $http
					.get(url + '?cachebust=' + new Date().getTime())
					.then(function (result) {
						return result.data.history;
					})
					.catch(function (error) {
						console.log('error', error);
					});
			}
		})
		.controller('ActivationCtrl', [
			'$scope',
			'$rootScope',
			'$timeout',
			'$http',
			'$window',
			'$sce',
			'$translate',
			'daybackIO',
			'crypt',
			'hash',
			'utilities',
			'activationMessages',
			'manageSettings',
			'seedcodeCalendar',
			'urlParameters',
			'environment',
			'groupSelector',
			ActivationCtrl,
		]);

	function ActivationCtrl(
		$scope,
		$rootScope,
		$timeout,
		$http,
		$window,
		$sce,
		$translate,
		daybackIO,
		crypt,
		hash,
		utilities,
		activationMessages,
		manageSettings,
		seedcodeCalendar,
		urlParameters,
		environment,
		groupSelector
	) {
		// Config may not exist if this is accessed from a new account sign up
		var config = seedcodeCalendar.get('config') || {}; // If the config object doesn't exist create empty object. This won't exist on new account creation dialogs or possibly on account error dialogs.

		var refreshStatusMessageShown;

		var platform;

		var dayPlural;

		var today = new Date();
		today.setHours(0, 0, 0, 0);
		today = today.getTime();

		$scope.isChildActivation =
			!!urlParameters.parentSubscriptionID &&
			!!urlParameters.parentCustomerID;

		$scope.isIos = environment.isIos;
		$scope.isPhone = environment.isPhone;
		$scope.platform = utilities.getDBKPlatform();
		$scope.isFileMakerWebViewer = utilities.getDBKPlatform() === 'dbkfm';
		$scope.isAdmin = config.admin; // The admin state will not be set from new sign up modal
		$scope.view = {};
		$scope.view.message = {};
		$scope.view.status = {};
		$scope.updates = {};

		$scope.activation = {};

		$scope.activation.config = seedcodeCalendar.get('config');

		$scope.activation.user = seedcodeCalendar.get('user');

		$scope.changeGroupPopover = groupSelector.changeGroupPopover;

		// Watching for config to update and then update scope variables that rely on it
		// Do not reference config variable in functions below needed for warning modals as that var might not be assigned yet
		// $scope references are OK as those would get updated dynamically after the on.config is called
		$scope.$on('config', function (newValue, oldValue) {
			config = seedcodeCalendar.get('config');
			$scope.isAdmin = config.admin;
			$scope.activation.config = config;
		});

		$scope.gotoPurchase = function () {
			// If we are in a modal let's close it first
			if ($scope.popover && $scope.popover.config) {
				$scope.popover.config.show = false;
			}
			hash.gotoPurchase(function () {
				$scope.refreshActivation(true);
			});
		};

		$scope.activateChildSubscription = function () {
			var values = {
				view: $scope.view,
				members: $scope.groupMembers,
				quantity: $scope.groupMembers.length,
				parentSubscriptionID: urlParameters.parentSubscriptionID,
				parentCustomerID: urlParameters.parentCustomerID,
				activationCallback: function () {
					$scope.activated = true;
					$scope.refreshActivation(true);
				},
			};

			var config = {
				controller: 'SubscriptionsCtrl',
				target: '',
				container: 'body',
				type: 'modal', // modal or popover
				reserveWidth: '',
				positionX: 'auto',
				positionY: 'auto',
				direction: 'auto',
				data: values,
				dataTitle: 'activation',
				destroy: true,
				width: 600,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
			};
			utilities.popover(
				config,
				'<div ng-include="\'app/activation/subscriptions/activate-child-subscription.html\'"></div>'
			);
		};

		$timeout(function () {
			//Wrap this in a set timeout so we refresh the view regardless of how we landed there
			$scope.hash = hash.get();
			console.log('hash', hash);
			$scope.showBillingPortalURL = checkValidBillingPortalURL(
				$scope.hash
			);
			$scope.subscriptionURL = hash.getManageURL();

			if (!$scope.showBillingPortalURL) {
				$scope.getBillingPortalURL();
			}
		}, 0);

		setPurchaseURL();

		function setPurchaseURL() {
			var purchaseURL = hash.getPurchaseURL($scope.isFileMakerWebViewer);

			if (!purchaseURL) {
				window.setTimeout(setPurchaseURL, 500);
			} else {
				$scope.$evalAsync(function () {
					$scope.purchaseURL = purchaseURL;
				});
			}
		}

		$scope.getBillingPortalURL = function () {
			$scope.fetchingBillingPortalURL = true;
			hash.getBillingPortalURL(successCallback, errorCallback);
			function successCallback(result) {
				hash.update('billingPortalURL', result.url);
				hash.update(
					'billingPortalURLExpiration',
					result.new_link_available_at
				);
				manageSettings.setActivation(
					null,
					{
						billingPortalURL: result.url,
						billingPortalURLExpiration:
							result.new_link_available_at,
					},
					null
				);
				$scope.$evalAsync(function () {
					$scope.fetchingBillingPortalURL = false;
					$scope.showBillingPortalURL = checkValidBillingPortalURL(
						hash.get()
					);
				});
			}
			function errorCallback() {
				$scope.$evalAsync(function () {
					$scope.fetchingBillingPortalURL = false;
					$scope.showBillingPortalURL = checkValidBillingPortalURL(
						hash.get()
					);
				});
			}
		};

		function checkValidBillingPortalURL(hash) {
			if (!hash.hasActivationObject) {
				return;
			}
			var now = moment();
			var urlExpiresDate = moment(hash.billingPortalURLExpiration).add(
				1,
				'day'
			);

			if (hash.billingPortalURL && now.isBefore(urlExpiresDate)) {
				return true;
			} else {
				return false;
			}
		}

		$scope.logout = function (refresh) {
			daybackIO.signOut();

			if (refresh) {
				$timeout(function () {
					$window.location.reload();
				}, 0);
			}
		};

		$scope.refreshActivation = function (preventDialog) {
			var message =
				"It may take a few minutes for changes made to your subscription to be reflected here. If you don't see changes reflected wait a moment and refresh again.";
			if (!refreshStatusMessageShown && !preventDialog) {
				refreshStatusMessageShown = true;
				utilities.showModal(
					'Refresh Status',
					message,
					'OK',
					null,
					null,
					null
				);
			}
			$scope.fetchingActivation = true;
			manageSettings.getActivation(processActivation);

			function processActivation(activationObj) {
				crypt.verifyHash(activationObj, null, null, callback, null);
			}

			function callback() {
				$scope.$evalAsync(function () {
					$scope.hash = hash.get();
					selectView($scope.hash, today);
					$scope.fetchingActivation = false;

					$scope.showBillingPortalURL = checkValidBillingPortalURL(
						$scope.hash
					);
					$scope.subscriptionURL = hash.getManageURL();

					if (!$scope.showBillingPortalURL) {
						$scope.getBillingPortalURL();
					}

					$rootScope.$broadcast('activation', hash.get());
				});
			}
		};
		var hashValue = hash.get();

		$scope.isIos = environment.isIos;
		$scope.isFileMakerWebViewer = utilities.getDBKPlatform() === 'dbkfm';
		$scope.isSalesforce = utilities.getDBKPlatform() === 'dbksf';
		$scope.view = {};
		$scope.view.message = {};
		$scope.view.status = {};
		$scope.updates = {};

		$timeout(function () {
			//Wrap this in a set timeout so we refresh the view regardless of how we landed there
			$scope.hash = hash.get();
			$scope.subscriptionURL = hash.getManageURL();
			$scope.purchaseURL = hash.getPurchaseURL(
				$scope.isFileMakerWebViewer
			);
		}, 0);

		$translate('Current version', {version: hashValue.version})
			.then(function (translated) {
				$scope.updates.currentVersion = translated;
			})
			.catch(function (error) {
				$scope.updates.curentVersion =
					"You're running version " + hashValue.version;
			});

		//Get our remote messaging
		var activationMessageURL = $scope.isFileMakerWebViewer
			? 'http://www.seedcode.com/about/dayback.json'
			: 'messages/dayback.json?cachebust=1';
		activationMessages
			.getActivationMessages(activationMessageURL)
			.then(function (data) {
				var randomProperty = function (obj) {
					var keys = Object.keys(obj);
					return obj[keys[(keys.length * Math.random()) << 0]];
				};
				$scope.view.feed = data;
				$scope.view.feed.trial = randomProperty(data.trial);
			});

		//Get our remote update messaging
		var updateMessageURL = $scope.isFileMakerWebViewer
			? 'http://dayback.io/web-calendar/version-history.json'
			: 'updates/version-history.json';
		var versionHistoryMessage = $scope.isFileMakerWebViewer
			? "<p>Please see <a href='http://www.seedcode.com/pmwiki/index.php?n=DayBackForFileMaker.VersionHistory' target='_blank'>version history</a> for what's new</p>"
			: "<p>Please see <a href='http://www.seedcode.com/pmwiki/index.php?n=DayBackOnline.VersionHistory' target='_blank'>version history</a> for what's new</p>";
		activationMessages
			.getUpdateMessages(updateMessageURL)
			.then(function (data) {
				var hashValue = hash.get();
				var currentVersion = hashValue.version;
				var previousVersion = hashValue.previousVersion;
				// var message;
				// var requiresFileMakerUpdate;
				// var updateInstructions = "";
				// for (var i = 0; i < data.length; i++) {
				// 	//If we have looped beyond our previous version exit the loop
				// 	if (previousVersion && Number(data[i].version) && (Number(data[i].version) <= Number(previousVersion))) {
				// 		break;
				// 	}
				//
				// 	if (Number(currentVersion) === Number(data[i].version)) {
				// 		message = data[i].changes;
				// 	}
				// 	if (data[i].filemaker === "true") {
				// 		requiresFileMakerUpdate = true;
				// 		updateInstructions = "<h3>Additional Instructions:</h3><p>You have upgraded from version " + previousVersion + ". There were a few FileMaker changes required between that version and version " + currentVersion + ". Please look at the <a href='http://www.seedcode.com/pmwiki/index.php?n=DayBackForFileMaker.VersionHistory' target='_blank'>version history</a> and apply the appropriate changes for each version in between your current upgrade.</p>";
				// 	}
				//
				// }
				var updateData = getUpdateMessageData(
					currentVersion,
					previousVersion,
					data
				);

				$scope.view.changes = updateData.changes
					? $sce.trustAsHtml(
							updateData.changes + updateData.updateInstructions
					  )
					: $sce.trustAsHtml(
							versionHistoryMessage +
								updateData.updateInstructions
					  );
			});

		manageSettings.getGroupMembers(processGroupMembers);

		// Watch for group member data to update (specifically the active switch)
		$scope.$on('memberActiveUpdate', function (e, groupMembers) {
			// $scope.hash = hash.get();
			processGroupMembers(groupMembers);
		});

		function processGroupMembers(groupMembers) {
			var activeMembers = 0;
			var inactiveMembers = 0;
			var output = [];

			if (Array.isArray(groupMembers)) {
				for (var i = 0; i < groupMembers.length; i++) {
					if (
						groupMembers[i].active !== false &&
						groupMembers[i].userID
					) {
						activeMembers++;
					} else {
						inactiveMembers++;
					}

					output.push(groupMembers[i]);
				}
			} else {
				for (var property in groupMembers) {
					if (
						groupMembers[property].active !== false &&
						groupMembers[property].userID
					) {
						activeMembers++;
					} else {
						inactiveMembers++;
					}

					output.push(groupMembers[property]);
				}
			}
			$timeout(function () {
				$scope.groupMembers = output;
				$scope.activeMembers = activeMembers;
				$scope.inactiveMembers = inactiveMembers;
			}, 0);
		}

		//Calculate expiration days
		// $scope.view.expires = hashValue.expiresDate ? Math.abs(hashValue.expiresDate.diff(today, 'days')) : '';
		$scope.view.expires = hash.getExpirationDays(); //hashValue.expiresDate ? Math.abs(utilities.numberToDays(hashValue.expiresDate - today)) : '';
		$translate(['day', 'days']).then(function (translated) {
			dayPlural = translated;
			//Determine view to load
			selectView(hashValue, today);
		});

		function selectView(hashValue, today) {
			$scope.view.preventFadeTransition = true;
			if (hashValue.type === 'trial' && today >= hashValue.expiresDate) {
				changeView('trial-expired');
			} else if (
				hashValue.type === 'subscription' &&
				!hashValue.hasActivationObject &&
				today >= hashValue.expiresDate
			) {
				changeView('renew');
			} else if (hashValue.type === 'inactive') {
				changeView('inactive');
			} else if (hashValue.type === 'overdue') {
				changeView('overdue');
			} else if (hashValue.trialStart) {
				changeView('start');
			} else if (hashValue.type === 'trial') {
				changeView('trial');
			} else if (hashValue.type === 'order') {
				changeView('purchased');
			} else if (hashValue.activated) {
				changeView('manage');
			}
			$timeout(function () {
				$scope.view.preventFadeTransition = false;
			}, 1000);
		}

		//Scope functions
		$scope.changeView = changeView;

		$scope.close = function (e) {
			//Stop propagation so we don't close popovers behind the modal when clicking close
			e.stopPropagation();
			$scope.popover.config.show = false;
		};
		$scope.refresh = function () {
			$window.location.reload();
		};
		$scope.openURL = function (url) {
			window.open(url);
		};
		$scope.runFilemakerScript = function (script) {
			utilities.scriptURL('script=' + script);
		};

		$scope.help = function (page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		};
		$scope.enterLicense = function (refreshOnSuccess) {
			$scope.view.license = $scope.view.license ? false : true;

			if (!$scope.view.license) {
				$timeout(function () {
					$scope.view.license = false;
				}, 0);
			}

			if (refreshOnSuccess) {
				$scope.$on('activation', function (hashValue) {
					$timeout(function () {
						$window.location.reload();
					}, 1000);
				});
			}
		};
		$scope.disableActivation = function (orderNumber, email, name) {
			if (!orderNumber || !email || !name) {
				return true;
			} else {
				return false;
			}
		};
		$scope.activate = function (orderNumber, email, name) {
			var callback = function (activationResult) {
				var platform = utilities.getDBKPlatform();
				var errorMessage;
				$timeout(function () {
					$scope.view.activating = false;
				}, 0);
				if (activationResult && !activationResult.error) {
					//Success
					hashValue = hash.get();
					$translate('Activation successful')
						.then(function (translated) {
							$scope.view.message.text =
								$sce.trustAsHtml(translated);
						})
						.catch(function (error) {
							$scope.view.message.text = $sce.trustAsHtml(
								'Activation successful'
							);
						});
					$scope.view.message.class = 'success';

					$timeout(function () {
						var view =
							hashValue.type === 'subscription'
								? 'manage'
								: 'purchased';
						$scope.view.expires = hash.getExpirationDays(); //hashValue.expiresDate ? Math.abs(utilities.numberToDays(hashValue.expiresDate - today)) : '';
						$scope.view.license = false;
						$scope.hash = hashValue;
						$scope.subscriptionURL = hash.getManageURL();
						$scope.purchaseURL = hash.getPurchaseURL(
							$scope.isFileMakerWebViewer
						);

						changeView(view);
						//Broadcast our successful activation so we can subscribe to this event
						$rootScope.$broadcast('activation', hashValue);
					}, 0);
				} else {
					if (activationResult && activationResult.message) {
						errorMessage = activationResult.message;

						if (
							(platform === 'dbkfmjs' ||
								platform === 'dbkfmwd') &&
							errorMessage.indexOf('DayBack Classic License') > -1
						) {
							errorMessage =
								'It appears you have entered a DayBack Classic license; this will not work with the new DayBack for FileMaker.';
						}
					} else {
						errorMessage =
							'The activation request was denied. Please check your information and try again';
					}
					$translate(errorMessage)
						.then(function (translated) {
							$scope.view.message.text =
								$sce.trustAsHtml(translated);
						})
						.catch(function (error) {
							$scope.view.message.text =
								$sce.trustAsHtml(errorMessage);
						});
					$scope.view.message.class = 'error';
				}
			};

			//Run the activation process
			$scope.view.activating = true;
			$scope.view.message = {};
			crypt.activate(orderNumber, email, name, callback);
		};

		$scope.checkForUpdate = function (currentVersion) {
			currentVersion = currentVersion
				? currentVersion
				: hash.get().version;
			$scope.updates.checking = true;
			crypt
				.checkForUpdate(
					updateMessageURL + '?cachebust=' + new Date().getTime()
				)
				.then(
					function (data) {
						var versionAvailable = data.history[0].version;

						var updateData = getUpdateMessageData(
							versionAvailable,
							hashValue.version,
							data.history
						);

						var message = $sce.trustAsHtml(
							updateData.updateNote || updateData.message
						);

						if (
							parseFloat(currentVersion, 10) <
							parseFloat(versionAvailable, 10)
						) {
							$translate('Version available', {
								version: versionAvailable,
							})
								.then(function (translated) {
									$scope.updates.message = translated;
								})
								.catch(function (error) {
									$scope.updates.message =
										'Version ' +
										versionAvailable +
										' available.';
								});

							$scope.updates.instructions = message;
							$scope.updates.available = true;
							$scope.updates.checking = false;
						} else {
							$translate('Running latest version', {
								version: versionAvailable,
							})
								.then(function (translated) {
									$scope.updates.message = translated;
								})
								.catch(function (error) {
									$scope.updates.message =
										'You are running the latest version';
								});
							$scope.updates.available = false;
							$scope.updates.checking = false;
						}
					},
					function (error) {
						$translate('Cannot connect')
							.then(function (translated) {
								$scope.updates.message = translated;
							})
							.catch(function (error) {
								$scope.updates.message =
									"Couldn't connect to update server";
							});
						$scope.updates.available = false;
						$scope.updates.checking = false;
					}
				);
		};

		$scope.installUpdate = function () {
			var upgradeDirectory;
			//Run update

			crypt
				.getUpgradeDirectory(
					'http://dayback.io/web-calendar/get-update/' +
						'?referenceID=' +
						hashValue.reference +
						'&type=' +
						hashValue.type
				)
				.then(
					function (data) {
						upgradeDirectory = data;
						utilities.scriptURL(
							'script=Install%20Update%20From%20WebViewer&$directory=' +
								upgradeDirectory
						);
					},
					function (error) {
						$translate('Problem downloading update')
							.then(function (translated) {
								$scope.updates.message = translated;
							})
							.catch(function (error) {
								$scope.updates.message =
									'There was a problem downloading the update';
							});
					}
				);
		};

		$scope.resetUserLicense = function () {
			crypt.resetUserLicense(hash.get().hash);
			$scope.reset = true;
			$timeout(function () {
				$window.location.reload();
			}, 4000);
		};

		//Functions
		// function processGroupMembers(groupMembers) {
		// 	var output = [];
		// 	for (var property in groupMembers) {
		// 		output.push(groupMembers[property]);
		// 	}
		// 	$timeout(function() {
		// 		$scope.groupMembers = output;
		// 	}, 0);
		// }

		function changeView(view) {
			var dayString;
			var dayLabel;

			var hashValue = hash.get();

			$scope.purchaseURL = hash.getPurchaseURL(
				$scope.isFileMakerWebViewer
			);

			//Calculate expiration days
			// $scope.view.expires = hashValue.expiresDate ? Math.abs(hashValue.expiresDate.diff(today, 'days')) : '';
			$scope.view.expires = hash.getExpirationDays(); //hashValue.expiresDate ? Math.abs(utilities.numberToDays(hashValue.expiresDate - today)) : '';
			var dayLabel;

			manageSettings.getGroupMembers(processGroupMembers);

			if (view === 'trial-expired') {
				$scope.view.mode = view;
				$scope.view.expired = true;
				$scope.view.message.class = 'error';
				$translate('Trial expired message', {days: dayString})
					.then(function (translated) {
						$scope.view.message.text = $sce.trustAsHtml(translated);
					})
					.catch(function (error) {
						$scope.view.message.text = $sce.trustAsHtml(
							'Trial expired. Please purchase a license to continue.'
						);
					});
			} else if (view === 'renew') {
				dayLabel =
					$scope.view.expires === 1 ? dayPlural.day : dayPlural.days;
				dayString = $scope.view.expires + ' ' + dayLabel;
				$translate('Subscription expired', {days: dayString})
					.then(function (translated) {
						$scope.view.status.text = translated;
					})
					.catch(function (error) {
						$scope.view.status.text =
							'Subscription expired ' +
							$scope.view.expires +
							' days ago';
					});
				$scope.view.mode = view;
				$scope.view.expired = true;
				$scope.view.message.class = 'error';
				$translate('Subscription expired message', {days: dayString})
					.then(function (translated) {
						$scope.view.message.text = $sce.trustAsHtml(translated);
					})
					.catch(function (error) {
						$scope.view.message.text = $sce.trustAsHtml(
							'Subscription expired. Please renew to continue.'
						);
					});
				if (hashValue.cutoffDate > today) {
					$scope.view.showClose = true;
					$scope.view.message.class = 'error';
					dayLabel =
						utilities.numberToDays(hashValue.cutoffDate - today) ===
						1
							? dayPlural.day
							: dayPlural.days;
					dayString =
						utilities.numberToDays(hashValue.cutoffDate - today) +
						' ' +
						dayLabel;
					$translate('Cease to function message', {days: dayString})
						.then(function (translated) {
							$scope.view.message.text =
								$sce.trustAsHtml(translated);
						})
						.catch(function (error) {
							$scope.view.message.text = $sce.trustAsHtml(
								'If a new license is not entered the calendar interface will cease to function in ' +
									utilities.numberToDays(
										hashValue.cutoffDate - today
									) +
									' days'
							);
						});
				}
			} else if (view === 'inactive') {
				$scope.view.mode = view;
				$scope.view.expired = true;
				$scope.view.message.class = 'error';
				$translate('Subscription inactive message')
					.then(function (translated) {
						$scope.view.message.text = $sce.trustAsHtml(translated);
					})
					.catch(function (error) {
						$scope.view.message.text = $sce.trustAsHtml(
							'Subscription inactive. Please renew to continue.'
						);
					});
			} else if (view === 'overdue') {
				$scope.view.mode = view;
				$scope.view.expired = false;
				$scope.view.message.class = 'error';
				// Looking at config.admin variable which doesn't exist on new account creation or warning popover.
				// This is not shown as a dialog so it's ok to reference that here.
				$translate([
					'Subscription overdue message admin',
					'Subscription overdue message non admin',
				])
					.then(function (translated) {
						$scope.view.message.text = config.admin
							? $sce.trustAsHtml(
									translated[
										'Subscription overdue message admin'
									]
							  )
							: $sce.trustAsHtml(
									translated[
										'Subscription overdue message non admin'
									]
							  );
					})
					.catch(function (error) {
						$scope.view.message.text = $sce.trustAsHtml(
							config.admin
								? 'Subscription payment is past due. Manage your subscription and update payment info.'
								: 'Subscription payment is past due. Contact us to update payment info.'
						);
					});
			} else if (view === 'start') {
				dayLabel = dayPlural.day;
				dayString = $scope.view.expires + ' ' + dayLabel;
				$translate('Trial activated', {days: dayString})
					.then(function (translated) {
						$scope.view.status.text = translated;
					})
					.catch(function (error) {
						$scope.view.status.text =
							'Your ' +
							$scope.view.expires +
							' trial has been activated';
					});
				hashValue.trialStart = false;
				$scope.view.mode = view;
			} else if (view === 'trial') {
				dayLabel =
					$scope.view.expires === 1 ? dayPlural.day : dayPlural.days;
				dayString = $scope.view.expires + ' ' + dayLabel;
				$translate('Trial remains', {days: dayString})
					.then(function (translated) {
						$scope.view.status.text = translated;
					})
					.catch(function (error) {
						$scope.view.status.text =
							$scope.view.expires + ' days left in your trial';
					});
				$scope.view.mode = view;
			} else if (view === 'purchased') {
				$scope.view.mode = view;
				if (today >= hashValue.expiresDate) {
					$scope.view.expires = 0;
				}
				if (today >= hashValue.cutoffDate) {
					$scope.view.noUpdates = true;
				}
				dayLabel =
					$scope.view.expires === 1 ? dayPlural.day : dayPlural.days;
				dayString = $scope.view.expires + ' ' + dayLabel;
				$scope.view.message.class = 'default';
				$translate('Free updates remaining message', {days: dayString})
					.then(function (translated) {
						$scope.view.message.text = $sce.trustAsHtml(
							"<a href='http://www.seedcode.com/extend-in-app-updates/' target='_blank'>" +
								translated +
								'</a>'
						);
					})
					.catch(function (error) {
						$scope.view.message.text = $sce.trustAsHtml(
							"<a href='http://www.seedcode.com/extend-in-app-updates/' target='_blank'>" +
								$scope.view.expires +
								' days of in-app updates remaining' +
								'</a>'
						);
					});
			} else if (view === 'manage') {
				dayLabel =
					Math.abs($scope.view.expires) === 1
						? dayPlural.day
						: dayPlural.days;
				dayString = Math.abs($scope.view.expires) + ' ' + dayLabel;
				$translate(
					[
						'Subscription renews',
						'Subscription renews today',
						'Subscription should have renewed',
					],
					{days: dayString}
				)
					.then(function (translated) {
						if ($scope.view.expires == 0) {
							$scope.view.status.text =
								translated['Subscription renews today'];
						} else if ($scope.view.expires < 0) {
							$scope.view.status.text =
								translated['Subscription should have renewed'];
						} else {
							$scope.view.status.text =
								translated['Subscription renews'];
						}
					})
					.catch(function (error) {
						$scope.view.status.text =
							'Subscription renews in ' +
							$scope.view.expires +
							' days';
					});
				$scope.view.mode = view;
			}
		}

		function getUpdateMessageData(newVersion, oldVersion, updateData) {
			var result = {
				message: '',
				changes: '',
				requiresFileMakerUpdate: false,
				updateInstructions: '',
				updateNote: '',
			};

			if (!updateData) {
				return result;
			}

			for (var i = 0; i < updateData.length; i++) {
				//If we have looped beyond our previous version exit the loop
				if (
					oldVersion &&
					Number(updateData[i].version) &&
					Number(updateData[i].version) <= Number(oldVersion)
				) {
					break;
				}

				if (Number(newVersion) === Number(updateData[i].version)) {
					result.message = updateData[i].message;
					result.changes = updateData[i].changes;
				}
				if (updateData[i].filemaker === 'true') {
					result.requiresFileMakerUpdate = true;
					result.updateInstructions =
						'<h3>Additional Instructions:</h3><p>You have upgraded from version ' +
						oldVersion +
						'. There were a few FileMaker changes required between that version and version ' +
						newVersion +
						". Please look at the <a href='http://www.seedcode.com/pmwiki/index.php?n=DayBackForFileMaker.VersionHistory' target='_blank'>version history</a> and apply the appropriate changes for each version in between your current upgrade.</p>";
					result.updateNote =
						'<i style="color: orange; float: left; margin: 5px 20px; font-size: 32px;" class="fa fa-exclamation-triangle"></i><p style="text-align: left;">There are a few FileMaker changes required between ' +
						oldVersion +
						' and ' +
						newVersion +
						". Please look at the <a href='http://www.seedcode.com/pmwiki/index.php?n=DayBackForFileMaker.VersionHistory' target='_blank'>version history</a> and apply the appropriate changes for each version in between after updating.</p>";
				}
			}
			return result;
		}
	}
})();
